@import url("./header.css");
@import url("./nav.css");
@import url("./sidebar.css");
@import url("./main.css");
@import url("./footer.css");

/* @import url("https://cdn.kernvalley.us/css/core-css/layout/index.css"); */

body {
	display: grid;
	grid-template-areas: "nav nav nav" ". main ." "footer footer footer";
	grid-template-rows: var(--nav-height, 4rem) minmax(calc(100vh - var(--nav-height, 4rem)), 1fr) auto;
	grid-template-columns: 12px 1fr 12px;
	gap: 8px;
}

button.btn[is="share-button"] {
	bottom: 1rem;
	right: 1rem;
	padding: 0.8rem;
	font-size: 1.5rem;
}

#create-btn {
	bottom: 1rem;
	left: 1rem;
	padding: 0.8rem;
	font-size: 1.5rem;
}

#get-geo {
	width: 200px;
}

#place-address-locality, #place-address-region, #place-postal-code {
	display: inline-block;
	width: auto;
}

#place-address-region {
	width: 4ch;
}

#place-postal-code {
	width: 8ch;
}

#add-place-dialog details.accordion > :not(summary) {
	padding: 0.6em 0.3em;
}

@media (max-width: 800px) {
	.mobile-hidden {
		display: none;
	}
}
