@charset "UTF-8";
@import url("https://cdn.kernvalley.us/css/core-css/theme/default/index.css");
@import url("./vars.css");
@import url("https://cdn.kernvalley.us/css/normalize/normalize.css");
@import url("https://cdn.kernvalley.us/css/core-css/rem.css");
@import url("https://cdn.kernvalley.us/css/core-css/viewport.css");
@import url("https://cdn.kernvalley.us/css/core-css/element.css");
@import url("https://cdn.kernvalley.us/css/core-css/class-rules.css");
@import url("https://cdn.kernvalley.us/css/core-css/cdn-fonts.css");
@import url("https://cdn.kernvalley.us/css/core-css/animations.css");
@import url("https://cdn.kernvalley.us/css/core-css/forms.css");
@import url("https://cdn.kernvalley.us/css/core-css/scrollbar.css");
@import url("https://cdn.kernvalley.us/css/core-css/scrollbar.css");
@import url("https://cdn.kernvalley.us/css/core-css/accordion.css");
@import url("./layout.css");

#btn-container {
	gap: 18px;
	justify-content: center;
	padding-bottom: 18px;
}

#btn-container .btn {
	max-width: 250px;
}

#business-hours {
	list-style: none;
}

#business-hours input {
	background-color: inherit;
	color: inherit;
	border-color: currentColor;
	border-width: 0 0 1px 0;
}

#add-place-dialog {
	width: 95vw;
	max-width: 800px;
}

fieldset[disabled] {
	opacity: 0.6;
	cursor: not-allowed;
}
